import cn from 'classnames';
import { isEqual } from 'lodash';
import * as React from 'react';
import { useRecoilValue } from 'recoil';
import { Editor, Range } from 'slate';
import { ReactEditor, useSlate, useSlateStatic } from 'slate-react';
import { KitemakerElement, KitemakerNode } from '../../../shared/slate/kitemakerNode';
import {
  CommentElement,
  DocumentLike,
  Elements,
  FormatHoverMode,
  LinkElement,
  Marks,
} from '../../../shared/slate/types';
import {
  emptyDocument,
  isDocumentEmpty,
  normalizeDocument,
  safeSelection,
} from '../../../shared/slate/utils';
import { EntityProvenanceType } from '../../../sync/__generated/models';
import { fixText, improveText, shortenText, translate } from '../../api/ai';
import { HideIfSmallerThan } from '../../components/hideIfSmallerThan';
import { Button, ButtonSize, ButtonStyle, IconButton } from '../../components/new/button';
import { ColorIndicator, ColorPickerContent } from '../../components/new/colorPicker';
import { CommentEditor } from '../../components/new/comments';
import { Icon } from '../../components/new/icon';
import { KeyboardShortcut } from '../../components/new/keyboardShortcut';
import {
  useDisableKeyNavigation,
  useEnableKeyNavigation,
} from '../../components/new/keyNavigation';
import { LoadingSpinner } from '../../components/new/loadingSpinner';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '../../components/new/menu/dropdownMenu';
import { TextInput } from '../../components/new/textInput';
import { Tooltip } from '../../components/new/tooltip';
import { toast } from '../../components/toast';
import { useConfiguration } from '../../contexts/configurationContext';
import { useConfirmation } from '../../contexts/confirmationContext';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { ResponsiveDesignSize, useIsSmallScreen } from '../../hooks/useResponsiveDesign';
import { useIsProductTierExceededAndNag } from '../../index/billingChecks';
import { useCreateComment } from '../../syncEngine/actions/comments';
import {
  useAddEntitiesToInsights,
  useCreateInsight,
  useDeleteInsightIfEmpty,
  useRemoveEntitiesFromInsights,
} from '../../syncEngine/actions/insights';
import { insightSelector } from '../../syncEngine/selectors/insights';
import { initiativeIdsForEntitySelector } from '../../syncEngine/selectors/intiatives';
import { addInsightKey, createNewEntityFromAnywhere, mainComboKey } from '../../utils/config';
import {
  isCommentHotkey,
  isCreateHotkey,
  isInsightsHotkey,
  isLinkHotkey,
} from '../../utils/keyEvents';
import { sanitizeUrl } from '../../utils/urls';
import { InsightsEntityPicker } from '../elements/insight';
import Hover, { HoverProvider } from '../hovers';
import { KitemakerEditor } from '../kitemakerEditor';
import { KitemakerTransforms } from '../kitemakerTransforms';
import { HistoryEditor } from '../plugins/history';
import { StaticSlateDocument } from '../staticSlate';
import { TextAreaHandle, TextAreaType } from '../textArea';
import styles from './formatHover.module.scss';

enum AIFunction {
  TRANSLATE,
  FIX_SPELLING_GRAMMER,
  IMPROVE,
  SHORTEN,
}

function FormatButton({
  mark,
  icon,
  active,
  tooltip,
}: {
  mark: Marks;
  icon: string;
  active: boolean;
  tooltip: React.ReactNode;
}) {
  const editor = useSlateStatic();

  return (
    <Tooltip content={tooltip}>
      <div
        className={cn(styles.formatButton, {
          [styles.active]: active,
        })}
        onMouseDown={e => e.preventDefault()}
      >
        <IconButton
          icon={icon}
          size={ButtonSize.Small}
          buttonStyle={active ? ButtonStyle.Bare : ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            KitemakerEditor.toggleMark(editor, mark);
          }}
        />
      </div>
    </Tooltip>
  );
}

function ColorButton({ color, onClick }: { color: string | null; onClick: () => void }) {
  return (
    <Tooltip content={<>Color</>}>
      <Button
        size={ButtonSize.Small}
        className={styles.tighterButton}
        onClick={onClick}
        buttonStyle={ButtonStyle.BareSubtle}
      >
        <ColorIndicator color={color} onClick={onClick} className={styles.colorPickerIcon} />
      </Button>
    </Tooltip>
  );
}

function LinkButton({ onClick }: { onClick: () => void }) {
  return (
    <Tooltip
      content={
        <>
          Link <KeyboardShortcut shortcut={`${mainComboKey}+k`} />
        </>
      }
    >
      <div className={styles.formatButton} onMouseDown={e => e.preventDefault()}>
        <IconButton
          icon="link"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      </div>
    </Tooltip>
  );
}

function NewIssueButton({ onClick }: { onClick: () => void }) {
  return (
    <Tooltip
      content={
        <>
          Create new work item
          <KeyboardShortcut shortcut={createNewEntityFromAnywhere} />
        </>
      }
    >
      <div className={styles.formatButton} onMouseDown={e => e.preventDefault()}>
        <IconButton
          icon="add"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onClick();
          }}
        />
      </div>
    </Tooltip>
  );
}

function CommentButton({ onClick }: { onClick: () => void }) {
  const editor = useSlateStatic();

  return (
    <Tooltip
      content={
        <>
          Comment <KeyboardShortcut shortcut={`${mainComboKey}+shift+m`} />
        </>
      }
    >
      <div className={styles.formatButton} onMouseDown={e => e.preventDefault()}>
        <IconButton
          icon="chat"
          size={ButtonSize.Small}
          buttonStyle={ButtonStyle.BareSubtle}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            const nodes = Array.from(
              Editor.nodes(editor, {
                match: n => KitemakerElement.isElement(n) && n.type === Elements.Comment,
              })
            );

            for (const [node, path] of nodes) {
              const [, startPath] = Editor.first(editor, path);
              const [, endPath] = Editor.last(editor, path);

              const anchor = Editor.start(editor, startPath);
              const focus = Editor.end(editor, endPath);

              const selection = safeSelection(editor);
              if (selection) {
                const range = Range.isBackward(selection)
                  ? { anchor: focus, focus: anchor }
                  : { anchor, focus };

                if (isEqual(range, selection)) {
                  editor.emit('openComment', node as CommentElement);
                }
              }
            }

            onClick();
          }}
        />
      </div>
    </Tooltip>
  );
}

function InsightsButton({ onClick }: { onClick: () => void }) {
  const isSmallScreen = useIsSmallScreen();
  return (
    <Tooltip
      content={
        <>
          Link insight <KeyboardShortcut shortcut={addInsightKey} />
        </>
      }
    >
      <div>
        {!isSmallScreen && (
          <Button
            onClick={onClick}
            buttonStyle={ButtonStyle.BareSubtle}
            icon="insights"
            className={styles.insightButton}
          >
            Link insight
          </Button>
        )}
        {isSmallScreen && (
          <IconButton
            onClick={onClick}
            buttonStyle={ButtonStyle.BareSubtle}
            icon="insights"
            className={styles.insightButton}
          />
        )}
      </div>
    </Tooltip>
  );
}

function AIButton({
  menuOpen,
  onMenuOpenChanged,
  onAIFunctionSelect,
}: {
  menuOpen: boolean;
  onMenuOpenChanged: (open: boolean) => void;
  onAIFunctionSelect: (f: AIFunction) => void;
}) {
  const editor = useSlate();
  function setMenuOpen(open: boolean) {
    if (open) {
      editor.setFakeSelection(editor.selection);
    }
    onMenuOpenChanged(open);
  }

  const AIFunctions = [
    { type: AIFunction.TRANSLATE, name: 'Translate' },
    { type: AIFunction.FIX_SPELLING_GRAMMER, name: 'Fix spelling and grammar' },
    { type: AIFunction.IMPROVE, name: 'Improve writing' },
    { type: AIFunction.SHORTEN, name: 'Make shorter' },
  ];

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <Tooltip
        content={
          <>
            Use AI <KeyboardShortcut shortcut="space+enter" />
          </>
        }
      >
        <DropdownMenuTrigger asChild>
          <Button
            buttonStyle={ButtonStyle.BareSubtle}
            size={ButtonSize.Small}
            className={cn('pr4', {
              [styles.menuOpen]: menuOpen,
            })}
            icon="ai"
          >
            <Icon style={{ fill: 'var(--grayA8)' }} icon="arrow_down" className="ml2" />
          </Button>
        </DropdownMenuTrigger>
      </Tooltip>
      <DropdownMenuContent
        className={cn(styles.blockDropdown, 'menuTiny')}
        onCloseAutoFocus={e => e.preventDefault()}
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        sideOffset={11}
        align="start"
      >
        {AIFunctions.map(t => (
          <DropdownMenuItem
            key={t.type}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setMenuOpen(false);
              onAIFunctionSelect(t.type);
            }}
          >
            {t.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function LinkMode({ onWrapInLink }: { onWrapInLink: (url: string | null) => void }) {
  const editor = useSlateStatic();
  const initialUrl = React.useMemo(() => {
    const [links] = Editor.nodes(editor, {
      match: n => KitemakerElement.isElement(n) && n.type === Elements.Link,
    });
    if (!links) {
      return '';
    }
    const link = links[0] as LinkElement;
    const url = link.url ?? KitemakerNode.safeString(link);
    return sanitizeUrl(url);
  }, []);
  const [url, setUrl] = React.useState(initialUrl);
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  return (
    <TextInput
      className={styles.linkInput}
      placeholder="Enter a URL"
      autoFocus={true}
      value={url}
      onChange={e => setUrl(e.currentTarget.value)}
      onFocus={() => disableKeyNav('link-editor')}
      onBlur={() => enableKeyNav('link-editor')}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          e.stopPropagation();
          onWrapInLink(url);
        }

        if (e.key === 'Escape') {
          onWrapInLink(null);
        }
      }}
    />
  );
}

function ColorMode({ onColorSet }: { onColorSet: (color: string | null) => void }) {
  return <ColorPickerContent noCustomColor showNull onColorPicked={onColorSet} />;
}

function InsightMode({
  feedbackId,
  onWrapInInsight,
  onHide,
}: {
  feedbackId: string;
  onWrapInInsight: (insightId: string) => void;
  onHide: () => void;
}) {
  const editor = useSlate();
  const ref = React.useRef<HTMLInputElement>(null);

  const [insightId, setInsightId] = React.useState<string | null>(null);
  const unmounted = React.useRef(false);
  const insight = useRecoilValue(insightSelector(insightId ?? ''));
  const insightRef = React.useRef(insight);
  insightRef.current = insight;

  const createInsight = useCreateInsight();
  const addEntitiesToInsights = useAddEntitiesToInsights();
  const removeEntitiesFromInsights = useRemoveEntitiesFromInsights();
  const deleteEmptyInsight = useDeleteInsightIfEmpty();

  React.useEffect(() => {
    ref.current?.focus();
  }, []);

  const getContents = React.useCallback(() => {
    const contents = KitemakerEditor.extractSelection(editor, editor.fakeSelection());
    if (!contents) {
      return;
    }
    const stringified = JSON.stringify(normalizeDocument(contents));
    return stringified;
  }, [editor]);

  React.useEffect(() => {
    return () => {
      unmounted.current = true;
      if (insightRef.current && !deleteEmptyInsight(insightRef.current.id)) {
        onWrapInInsight(insightRef.current.id);
      }
    };
  }, []);

  return (
    <div className="menuHuge menuPicker">
      <InsightsEntityPicker
        insight={insight}
        onDone={onHide}
        content={getContents()}
        onEntityAdded={(_insightIds: string[], entityId: string) => {
          const contents = getContents();
          if (!insightRef.current) {
            const newInsight = createInsight(feedbackId, contents);
            setInsightId(newInsight.id);
            insightRef.current = newInsight;
          }
          addEntitiesToInsights([insightRef.current.id], [entityId]);

          if (unmounted.current && insightRef.current) {
            onWrapInInsight(insightRef.current.id);
          }
        }}
        onEntityRemoved={(insightIds: string[], entityId: string) => {
          removeEntitiesFromInsights(insightIds, [entityId]);
        }}
        onCreateNew={onHide}
      />
    </div>
  );
}

function CommentModeComponent(
  {
    onCommentCreated,
  }: {
    onCommentCreated: (commentId: string | null) => void;
  },
  ref: React.Ref<TextAreaHandle>
) {
  const editor = useSlateStatic();
  const createComment = useCreateComment();

  return (
    <HoverProvider>
      <CommentEditor
        ref={ref}
        entityId={editor.entityId!}
        initialValue={emptyDocument()}
        className={styles.commentInput}
        autoFocus
        placeholder={<>Leave a comment</>}
        onClear={() => onCommentCreated(null)}
        onSubmit={body => {
          const comment = createComment(editor.entityId!, JSON.stringify(body), {
            inline: true,
            context: KitemakerEditor.extractSelection(editor, editor.fakeSelection()),
          });
          if (comment) {
            onCommentCreated(comment.id);
          }
        }}
        type={TextAreaType.InputMedium}
        showPlaceholderOnFirstParagraph
      />
    </HoverProvider>
  );
}

export const CommentMode = React.forwardRef(CommentModeComponent);

export function AISuggestionMode({ type, onHide }: { type: AIFunction; onHide: () => void }) {
  const editor = useSlate();
  const organization = useOrganization();
  const [result, setResult] = React.useState<DocumentLike>(emptyDocument());
  const [working, setIsWorking] = React.useState(true);

  async function applyAIOnSelection() {
    setIsWorking(true);
    const innerContents = KitemakerEditor.extractSelection(editor);
    if (!innerContents) {
      toast.error('Failed to grab selected text');
      return;
    }

    function applyResult(result: any) {
      if (!result.document) {
        toast.error('Failed to get AI suggestion');
        onHide();
        return;
      }
      setResult(result.document);

      setIsWorking(false);
    }

    try {
      switch (type) {
        case AIFunction.TRANSLATE: {
          const result = await translate(organization.id, innerContents);
          applyResult(result);
          break;
        }

        case AIFunction.FIX_SPELLING_GRAMMER: {
          const result = await fixText(organization.id, innerContents);
          applyResult(result);
          break;
        }

        case AIFunction.IMPROVE: {
          const result = await improveText(organization.id, innerContents);
          applyResult(result);
          break;
        }

        case AIFunction.SHORTEN: {
          const result = await shortenText(organization.id, innerContents);
          applyResult(result);
          break;
        }

        default: {
          toast.error('AI function not implemented');
          onHide();
        }
      }
    } catch (e) {
      toast.error('Error applying AI', e.message);
      onHide();
    }
  }

  React.useEffect(() => {
    applyAIOnSelection();
  }, []);

  return (
    <HoverProvider>
      <div className={styles.aiSuggestionHover}>
        {working && (
          <div className="row">
            Pondering...
            <LoadingSpinner className={styles.spinner} />
          </div>
        )}
        {!working && (
          <>
            <StaticSlateDocument className={styles.editor} value={result} />
            <div className={styles.toolbar}>
              <Button onClick={onHide}>Cancel</Button>
              <Button onClick={applyAIOnSelection}>Try again</Button>
              <Button
                buttonStyle={ButtonStyle.Primary}
                onClick={() => {
                  KitemakerTransforms.insertFragment(editor, result);
                  ReactEditor.focus(editor);
                  onHide();
                }}
              >
                Replace
              </Button>
            </div>
          </>
        )}
      </div>
    </HoverProvider>
  );
}

function BlockPicker({
  menuOpen,
  onMenuOpenChanged,
}: {
  menuOpen: boolean;
  onMenuOpenChanged: (open: boolean) => void;
}) {
  const editor = useSlate();
  const type = KitemakerElement.determineCurrentTextBlockType(editor, safeSelection(editor));

  function setMenuOpen(open: boolean) {
    if (open) {
      editor.setFakeSelection(editor.selection);
    }
    onMenuOpenChanged(open);
  }

  function setType(type: Elements) {
    setMenuOpen(false);
    KitemakerTransforms.changeTextBlockType(editor, type);

    setTimeout(() => {
      ReactEditor.focus(editor);
      editor.setFakeSelection(null);
    });
  }

  const formatBlockTypes = [
    { type: Elements.Paragraph, icon: 'paragraph' },
    { type: Elements.Headline1, icon: 'h1' },
    { type: Elements.Headline2, icon: 'h2' },
    { type: Elements.Headline3, icon: 'h3' },
    { type: Elements.Bulleted, icon: 'list_bulleted' },
    { type: Elements.Numbered, icon: 'list_numbered' },
    ...[
      editor.smartTodosEnabled
        ? { type: Elements.SmartTodo, icon: 'todo_checkmark' }
        : { type: Elements.Todo, icon: 'todo_checkmark' },
    ],
    { type: Elements.Code, icon: 'text_style_code' },
    { type: Elements.BlockQuote, icon: 'quote' },
  ];

  return (
    <DropdownMenu open={menuOpen} onOpenChange={setMenuOpen}>
      <Tooltip
        content={
          <>
            Change type <KeyboardShortcut shortcut="/" />
          </>
        }
      >
        <DropdownMenuTrigger asChild>
          <Button
            icon={formatBlockTypes.find(t => t.type === type)?.icon ?? 'none'}
            buttonStyle={ButtonStyle.BareSubtle}
            size={ButtonSize.Small}
            className={cn('pr4', {
              [styles.menuOpen]: menuOpen,
            })}
          >
            <HideIfSmallerThan size={ResponsiveDesignSize.Small}>
              {type ? KitemakerElement.humanReadableName(type) : ''}
            </HideIfSmallerThan>
            <Icon style={{ fill: 'var(--grayA8)' }} icon="arrow_down" className="ml2" />
          </Button>
        </DropdownMenuTrigger>
      </Tooltip>
      <DropdownMenuContent
        className={cn(styles.blockDropdown, 'menuTiny')}
        onCloseAutoFocus={e => e.preventDefault()}
        onClick={e => {
          e.stopPropagation();
        }}
        side="bottom"
        sideOffset={11}
        align="start"
      >
        {formatBlockTypes.map(t => (
          <DropdownMenuCheckboxItem
            key={t.type}
            checked={t.type === type}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setType(t.type);
            }}
            icon={t.icon}
          >
            {KitemakerElement.humanReadableName(t.type)}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function FormatHover() {
  const organization = useOrganization();
  const maybeSpace = useMaybeSpace();
  const editor = useSlate();
  const modals = useModals();
  const interactivityDisabled = KitemakerElement.focusIsInNonInteractiveElement(editor);
  const [nonInteractive, setNonInteractive] = React.useState(false);
  const [blockMenuOpen, setBlockMenuOpen] = React.useState(false);
  const [AIMenuOpen, setAIMenuOpen] = React.useState(false);

  const [visible, setVisible] = React.useState(false);
  const [forceHide, setForceHide] = React.useState(false);
  const [mode, setMode] = React.useState(FormatHoverMode.Normal);
  const [AIMode, setAIMode] = React.useState(AIFunction.TRANSLATE);

  const [color, setColor] = React.useState<string | null>(null);
  const [bold, setBold] = React.useState(false);
  const [italic, setItalic] = React.useState(false);
  const [underline, setUnderline] = React.useState(false);
  const [strikethrough, setStrikethrough] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [restoreSelection, setRestoreSelection] = React.useState<Range | null>(null);
  const checkProductTierExceeded = useIsProductTierExceededAndNag();
  const forcedFormatHoverMode = React.useRef<FormatHoverMode | null>(null);
  const { confirm, isOpen: isConfirmOpen } = useConfirmation();
  const commentInputRef = React.useRef<TextAreaHandle>(null);

  const initiativeIds = useRecoilValue(initiativeIdsForEntitySelector(editor?.entityId ?? ''));

  const { featureFlags } = useConfiguration();

  const hide = React.useCallback(() => {
    setVisible(false);
    setForceHide(false);
    setColor(null);
    setBold(false);
    setItalic(false);
    setUnderline(false);
    setStrikethrough(false);
    setCode(false);
    setMode(FormatHoverMode.Normal);
    setRestoreSelection(null);
    setBlockMenuOpen(false);
    if (visible) {
      editor.setFakeSelection(null);
    }
  }, [visible]);

  const storeFakeSelection = React.useCallback(() => {
    const selection = safeSelection(editor);
    editor.setFakeSelection(selection);
    setRestoreSelection(selection);
  }, []);

  useComponentDidMount(() => {
    function forceFormatHoverMode(mode: FormatHoverMode) {
      forcedFormatHoverMode.current = mode;
    }

    editor.on('forceFormatHoverMode', forceFormatHoverMode);
    return () => editor.off('forceFormatHoverMode', forceFormatHoverMode);
  });

  const { selection } = editor;
  const focused = ReactEditor.isFocused(editor);
  const hasTextSelection =
    selection && !Range.isCollapsed(selection) && !!Editor.string(editor, selection).length;
  const hasVoidSelection =
    editor.feedbackId &&
    selection &&
    KitemakerElement.focusIsInTopLevelVoidElement(editor, n => !n.insightId);

  React.useLayoutEffect(() => {
    if (
      (mode === FormatHoverMode.Link ||
        mode === FormatHoverMode.Insight ||
        mode === FormatHoverMode.Comment) &&
      (!selection || !Range.isCollapsed(selection) || isConfirmOpen)
    ) {
      return;
    }

    if (!focused || (!hasTextSelection && !hasVoidSelection)) {
      hide();
      return;
    }

    if (KitemakerEditor.isInCodeBlock(editor)) {
      hide();
      return;
    }

    if (forcedFormatHoverMode.current) {
      storeFakeSelection();
      setMode(forcedFormatHoverMode.current);
      forcedFormatHoverMode.current = null;
    }

    setColor(KitemakerEditor.findColor(editor));

    setBold(KitemakerEditor.hasMark(editor, Marks.Bold));
    setItalic(KitemakerEditor.hasMark(editor, Marks.Italic));
    setUnderline(KitemakerEditor.hasMark(editor, Marks.Underline));
    setStrikethrough(KitemakerEditor.hasMark(editor, Marks.Strikethrough));
    setCode(KitemakerEditor.hasMark(editor, Marks.Code));
    setForceHide(false);
    setVisible(true);
  }, [selection]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent) => {
      if (!interactivityDisabled && hasTextSelection) {
        if (isLinkHotkey(e)) {
          e.preventDefault();
          e.stopPropagation();
          storeFakeSelection();
          setMode(FormatHoverMode.Link);
          return true;
        }

        if (e.key === '/') {
          e.preventDefault();
          e.stopPropagation();
          setBlockMenuOpen(previous => !previous);
          return true;
        }

        if (isCommentHotkey(e)) {
          e.preventDefault();
          e.stopPropagation();
          storeFakeSelection();
          setMode(FormatHoverMode.Comment);
          return true;
        }
      }

      // we leave insights working so we can add insights to chat messages
      if (editor.feedbackId && isInsightsHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        storeFakeSelection();
        setMode(FormatHoverMode.Insight);
        return true;
      }

      if (!editor.disableModals && isCreateHotkey(e)) {
        e.preventDefault();
        e.stopPropagation();
        hide();
        if (checkProductTierExceeded()) {
          return true;
        }
        storeFakeSelection();
        let content = KitemakerEditor.extractSelection(editor);
        if (!content) {
          content = emptyDocument();
        }
        modals.openModal(Modals.NewEntity, {
          title: '',
          spaceId: maybeSpace?.id,
          spaceIds: maybeSpace?.id ? [maybeSpace.id] : undefined,
          initiativeIds,
          content,
          provenance: editor.entityId
            ? {
                provenanceType: EntityProvenanceType.CreatedFrom,
                entityId: editor.entityId,
              }
            : null,
          onCreated: () => {
            if (restoreSelection) {
              KitemakerTransforms.select(editor, restoreSelection);
            }
            ReactEditor.focus(editor);
            hide();
            setForceHide(true);
          },
        });
      }

      return false;
    },
    [visible, interactivityDisabled, hasTextSelection]
  );

  React.useEffect(() => {
    function onMouseDown() {
      setNonInteractive(true);
    }

    function onMouseUp() {
      setNonInteractive(false);
    }

    document.addEventListener('mousedown', onMouseDown);
    document.addEventListener('mouseup', onMouseUp);

    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  return (
    <Hover
      fixed={!!restoreSelection}
      nonInteractive={mode === FormatHoverMode.Normal ? nonInteractive : undefined}
      contentOptions={{
        className: styles.formatHoverContent,
        side: mode === FormatHoverMode.Insight && !hasVoidSelection ? 'bottom' : undefined,
        onFocusOutside: e => {
          e.preventDefault();
        },
      }}
      onOpenChange={async open => {
        if (
          mode === FormatHoverMode.Comment &&
          commentInputRef.current &&
          !isDocumentEmpty(commentInputRef.current.raw().children)
        ) {
          const confirmed = await confirm(
            'Discard comment?',
            'Are you sure you want to discard this comment?',
            {
              label: 'Discard',
              destructive: true,
            }
          );
          if (!confirmed) {
            commentInputRef.current?.focus();
            return;
          }
        }
        setForceHide(!open);
        if (!open) {
          hide();
        }
      }}
      content={
        <div
          className={cn(styles.formatHover, { [styles.insight]: mode === FormatHoverMode.Insight })}
          onMouseDown={e => {
            if (mode === FormatHoverMode.Normal) {
              e.stopPropagation();
              e.preventDefault();
            }
          }}
        >
          {mode === FormatHoverMode.Normal && (
            <div className="row metadataGap">
              {featureFlags.FEATURE_TOGGLE_AI_TEXT_OPERATIONS &&
                organization.aiEnabled &&
                !interactivityDisabled &&
                hasTextSelection && (
                  <AIButton
                    menuOpen={AIMenuOpen}
                    onMenuOpenChanged={setAIMenuOpen}
                    onAIFunctionSelect={t => {
                      storeFakeSelection();
                      setAIMode(t);
                      setMode(FormatHoverMode.AISuggestion);
                    }}
                  />
                )}

              {editor.feedbackId && (
                <InsightsButton
                  onClick={() => {
                    storeFakeSelection();
                    setMode(FormatHoverMode.Insight);
                  }}
                />
              )}
              {!interactivityDisabled && hasTextSelection && (
                <>
                  <BlockPicker menuOpen={blockMenuOpen} onMenuOpenChanged={setBlockMenuOpen} />

                  <FormatButton
                    mark={Marks.Bold}
                    icon="text_style_bold"
                    active={bold}
                    tooltip={
                      <>
                        Bold <KeyboardShortcut shortcut={`${mainComboKey}+b`} />
                      </>
                    }
                  />
                  <FormatButton
                    mark={Marks.Italic}
                    icon="text_style_italic"
                    active={italic}
                    tooltip={
                      <>
                        Italic <KeyboardShortcut shortcut={`${mainComboKey}+i`} />
                      </>
                    }
                  />
                  <FormatButton
                    mark={Marks.Underline}
                    icon="text_style_underline"
                    active={underline}
                    tooltip={
                      <>
                        Underline <KeyboardShortcut shortcut={`${mainComboKey}+u`} />
                      </>
                    }
                  />
                  <FormatButton
                    mark={Marks.Strikethrough}
                    icon="text_style_strikethrough"
                    active={strikethrough}
                    tooltip={
                      <>
                        Strikethrough <KeyboardShortcut shortcut={`${mainComboKey}+shift+s`} />
                      </>
                    }
                  />
                  <FormatButton
                    mark={Marks.Code}
                    icon="text_style_code"
                    active={code}
                    tooltip={
                      <>
                        Code <KeyboardShortcut shortcut={`${mainComboKey}+e`} />
                      </>
                    }
                  />

                  <ColorButton
                    color={color}
                    onClick={() => {
                      storeFakeSelection();
                      setMode(FormatHoverMode.Color);
                    }}
                  />
                  <LinkButton
                    onClick={() => {
                      storeFakeSelection();
                      setMode(FormatHoverMode.Link);
                    }}
                  />

                  {!editor.disableModals && (
                    <NewIssueButton
                      onClick={() => {
                        if (checkProductTierExceeded()) {
                          return;
                        }
                        storeFakeSelection();
                        const innerContents = KitemakerEditor.extractSelection(editor);
                        if (!innerContents) {
                          toast.error('Failed to grab selected text');
                          return;
                        }
                        modals.openModal(Modals.NewEntity, {
                          title: '',
                          spaceId: maybeSpace?.id,
                          spaceIds: maybeSpace?.id ? [maybeSpace.id] : undefined,
                          initiativeIds,
                          content: innerContents,
                          provenance: editor.entityId
                            ? {
                                provenanceType: EntityProvenanceType.CreatedFrom,
                                entityId: editor.entityId,
                              }
                            : null,
                          onCreated: () => {
                            if (restoreSelection) {
                              KitemakerTransforms.select(editor, restoreSelection);
                            }
                            ReactEditor.focus(editor);
                            hide();
                            setForceHide(true);
                          },
                        });
                      }}
                    />
                  )}
                  {editor.inlineComments && editor.entityId && (
                    <CommentButton
                      onClick={() => {
                        storeFakeSelection();
                        setMode(FormatHoverMode.Comment);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          )}
          {mode === FormatHoverMode.Link && (
            <LinkMode
              onWrapInLink={url => {
                setTimeout(() => {
                  if (restoreSelection) {
                    KitemakerTransforms.select(editor, restoreSelection);
                    if (url !== null) {
                      HistoryEditor.asBatch(editor, () => {
                        KitemakerTransforms.addLink(editor, url ? sanitizeUrl(url) : url, true);
                      });
                    }
                  }
                  ReactEditor.focus(editor);
                  hide();
                  setForceHide(true);
                });
              }}
            />
          )}
          {mode === FormatHoverMode.Color && (
            <ColorMode
              onColorSet={color => {
                setTimeout(() => {
                  if (restoreSelection) {
                    KitemakerTransforms.select(editor, restoreSelection);
                  }
                  if (color === null) {
                    HistoryEditor.asBatch(editor, () => {
                      KitemakerEditor.removeMark(editor, Marks.Color);
                    });
                  } else {
                    HistoryEditor.asBatch(editor, () => {
                      KitemakerEditor.addMark(editor, Marks.Color, color);
                    });
                  }
                  ReactEditor.focus(editor);
                  hide();
                  setForceHide(true);
                });
              }}
            />
          )}
          {mode === FormatHoverMode.Insight && (
            <InsightMode
              onHide={hide}
              feedbackId={editor.feedbackId!}
              onWrapInInsight={insightId => {
                setTimeout(() => {
                  if (restoreSelection) {
                    KitemakerTransforms.select(editor, restoreSelection);
                    if (insightId !== null) {
                      HistoryEditor.asBatch(editor, () => {
                        KitemakerTransforms.removeInsight(editor, insightId);
                        KitemakerTransforms.addInsight(editor, insightId);
                      });
                    }
                  }
                  ReactEditor.focus(editor);
                  hide();
                  setForceHide(true);
                });
              }}
            />
          )}
          {mode === FormatHoverMode.Comment && (
            <CommentMode
              ref={commentInputRef}
              onCommentCreated={commentId => {
                setTimeout(() => {
                  if (restoreSelection) {
                    KitemakerTransforms.select(editor, restoreSelection);
                    if (commentId) {
                      KitemakerTransforms.addComemnt(editor, commentId);
                    }
                  }
                  ReactEditor.focus(editor);
                  hide();
                  setForceHide(true);
                }, 100);
              }}
            />
          )}
          {mode === FormatHoverMode.AISuggestion && (
            <AISuggestionMode
              type={AIMode}
              onHide={() => {
                setAIMenuOpen(false);
                hide();
              }}
            />
          )}
        </div>
      }
      open={visible && !forceHide}
      hoverId="format"
      onKeyDown={onKeyDown}
    />
  );
}
