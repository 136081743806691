import cn from 'classnames';
import * as clipboard from 'clipboard-polyfill';
import { isEqual, isFunction, sortBy, uniq } from 'lodash';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Descendant } from 'slate';
import uuid from 'uuid';
import { COMMENT_THREAD_ID_QUERY_PARAM } from '../../../shared/comments';
import { AIPlaceholderElement, DocumentLike, Elements } from '../../../shared/slate/types';
import { emptyDocument, isDocumentEmpty } from '../../../shared/slate/utils';
import { issueTerm } from '../../../shared/utils/terms';
import { Comment as CommentModel, EntityProvenanceType } from '../../../sync/__generated/models';
import { CommandGroup } from '../../commands';
import { useClient } from '../../contexts/clientContext';
import { useConfiguration } from '../../contexts/configurationContext';
import { Modals, useModals } from '../../contexts/modalContext';
import { useOrganization } from '../../contexts/organizationContext';
import { useSearchOnce } from '../../contexts/searchContext';
import { useMaybeSpace } from '../../contexts/spaceContext';
import { useCurrentUser } from '../../contexts/userContext';
import { useEditableComment } from '../../hooks/useComment';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';
import { useIsProductTierExceededAndNag } from '../../index/billingChecks';
import { useSerializeToMarkdown } from '../../slate/hooks/useSerializeToMarkdown';
import { entitySuggestionMatcher } from '../../slate/plugins/suggestions/entitySuggestionMatcher';
import { userSuggestionMatcher } from '../../slate/plugins/suggestions/userSuggestionMatcher';
import { StaticSlateDocument } from '../../slate/staticSlate';
import { TextArea, TextAreaHandle, TextAreaProps, TextAreaType } from '../../slate/textArea';
import {
  useCreateComment,
  useDeleteComments,
  useUpdateComments,
} from '../../syncEngine/actions/comments';
import { useCreateInitiative } from '../../syncEngine/actions/intiatives';
import { useCreateIssue } from '../../syncEngine/actions/issues';
import {
  useMarkUpdatesReadByDependencyId,
  useMarkUpdatesReadByKey,
} from '../../syncEngine/actions/updates';
import {
  commentSelector,
  commentsForThreadSelector,
  useCommentUrl,
} from '../../syncEngine/selectors/comments';
import {
  spaceIdForEntitySelector,
  useEntityPath,
  useRecentEntitiesForOrganization,
  useRecentEntitiesForSpace,
} from '../../syncEngine/selectors/entities';
import {
  initiativeIdsForIssueSelector,
  useFilterInitiativesForSpace,
} from '../../syncEngine/selectors/intiatives';
import {
  activeUsersForMaybeSpaceSelector,
  spacesForOrganizationSelector,
} from '../../syncEngine/selectors/spaces';
import { useGetTodosForEntity } from '../../syncEngine/selectors/todos';
import { scrollCommentIntoView, useIsCommentOpenInline } from '../../utils/comments';
import {
  copyActivityLinkKey,
  createNewEntityFromAnywhere,
  reactionKey,
  resolveCommentKey,
} from '../../utils/config';
import { renderDate } from '../../utils/datetime';
import { removeQueryParameter, setQueryParameter } from '../../utils/query';
import { scrollIntoView } from '../../utils/scrolling';
import ActorName from '../actorName';
import { writeToClipboard } from '../clipboardText';
import { toast } from '../toast';
import { ActivityTimestamp } from './activities/activity';
import { ActorAvatar } from './actorAvatar';
import { Button, ButtonSize, ButtonStyle, IconButton } from './button';
import { CommandContext } from './commandMenuContext';
import styles from './comments.module.scss';
import { CustomCommand } from './customCommand';
import { Divider } from './divider';
import { EmojiPicker } from './emojiPicker';
import { Hotkey } from './hotkey';
import { KeyboardShortcut } from './keyboardShortcut';
import {
  FocusReason,
  KEY_NAVIGATION_DATA_ATTRIBUTE,
  KeyNavigationElement,
  useDisableKeyNavigation,
  useEnableKeyNavigation,
  useEnsureFocusedElementIsVisible,
  useGetKeyNavigationState,
  useHasKeyNavigationFocus,
  useKeyNavigationColumn,
  useKeyNavigationWatcher,
  useMove,
  useSetKeyNavigationFocus,
} from './keyNavigation';
import { KeyNavigationLockingElement } from './keyNavigation/keyNavigationDisablingElement';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './menu/dropdownMenu';
import { AvatarSize } from './metadata/avatar';
import Popover from './popover';
import { ReactionTooltip, Reactions, useToggleReactions } from './reactions';
import { Tooltip } from './tooltip';

export const NEW_COMMENT_ID = 'new-comment';

function useCreateEntityFromComment(comment: CommentModel) {
  const user = useCurrentUser();
  const modals = useModals();
  const { host } = useConfiguration();
  const entityPath = useEntityPath();
  const spaceId = useRecoilValue(spaceIdForEntitySelector(comment.entityId)) ?? undefined;
  const initiativeIds = useRecoilValue(initiativeIdsForIssueSelector(comment.entityId));
  const checkProductTierExceeded = useIsProductTierExceededAndNag();

  return React.useCallback(() => {
    if (checkProductTierExceeded()) {
      return;
    }
    const innerContents = JSON.parse(comment.body) as Descendant[];
    if (!innerContents) {
      toast.error('Failed to grab selected text');
      return;
    }

    let url = `${host}${entityPath(comment.entityId)}?commentId=${comment.id}`;
    if (comment.threadId) {
      url += `&commentThreadId=${comment.threadId}`;
    }

    const fromNodes = {
      type: 'paragraph',
      children: [
        {
          text: '',
        },
        {
          type: 'user',
          userId: user.id,
          mentionId: uuid.v4(),
          actorId: user.id,
          children: [
            {
              text: '',
            },
          ],
        },
        {
          text: ' ',
        },
        {
          type: 'link',
          url,
          fromHover: true,
          children: [
            {
              text: 'commented on',
            },
          ],
        },
        {
          text: ' ',
        },
        {
          type: 'entity',
          actorId: comment.actorId,
          entityId: comment.entityId,
          mentionId: uuid.v4(),
          children: [
            {
              text: '',
            },
          ],
        },
        {
          text: ':',
        },
      ],
    } as Descendant;

    innerContents.unshift(fromNodes);

    modals.openModal(Modals.NewEntity, {
      spaceId,
      title: '',
      content: innerContents,
      initiativeIds,
      provenance: {
        provenanceType: EntityProvenanceType.CreatedFrom,
        entityId: comment.entityId,
        createdFromCommentId: comment.id,
      },
    });
  }, [
    comment.actorId,
    comment.body,
    comment.entityId,
    comment.id,
    comment.threadId,
    entityPath,
    host,
    modals,
    spaceId,
  ]);
}

function InlineCommentContext({ comment }: { comment: CommentModel }) {
  const inlineCommentOpen = useIsCommentOpenInline([comment.id]);

  if (!comment.commentContext) {
    return null;
  }
  return (
    <StaticSlateDocument
      className={cn(styles.commentContext, 'fs-exclude', {
        [styles.resolved]: comment.resolved,
        [styles.highlighted]: inlineCommentOpen,
      })}
      value={comment.commentContext}
      voidBlockPlaceholders
    />
  );
}

export function Comment({
  comment,
  disableHotkeys,
  additionalActions,
  actionsAlwaysVisible,
  className,
  children,
}: {
  comment: CommentModel;
  disableHotkeys?: boolean;
  additionalActions?: React.ReactNode;
  actionsAlwaysVisible?: boolean;
  className?: string;
  children?: React.ReactNode;
}) {
  const organization = useOrganization();
  const user = useCurrentUser();
  const updateComments = useUpdateComments();
  const markRead = useMarkUpdatesReadByDependencyId();
  const [edit, setEdit] = React.useState(false);
  const focused = useHasKeyNavigationFocus(comment.id) && !disableHotkeys;
  const createEntityFromComment = useCreateEntityFromComment(comment);
  const [selecting, setSelecting] = React.useState(false);

  const onDragStart = React.useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (organization.aiEnabled) {
        e.dataTransfer.setData(
          'application/json',
          JSON.stringify({ type: 'comment', id: comment.id })
        );
      }
    },
    [comment.id, organization.aiEnabled]
  );

  const onPointerUp = React.useCallback(() => {
    setSelecting(false);
    document.removeEventListener('pointerup', onPointerUp);
  }, []);

  let slateDoc = (
    <StaticSlateDocument
      value={JSON.parse(comment.body)}
      className={cn('fs-exclude selectable', {
        mt6: !organization.aiEnabled,
      })}
      decorate
    />
  );

  if (organization.aiEnabled) {
    slateDoc = (
      <div
        className="mt6 unselectable"
        onPointerDown={() => {
          setSelecting(true);
          // can't do the thing below because of FF bugs :(, last tested on FF 126
          //(e.target as HTMLDivElement).setPointerCapture(e.pointerId);
          // instead we attach an event to window to capture the pointer up event
          document.addEventListener('pointerup', onPointerUp);
        }}
        // cant use onPointerUp because of FF bugs
        // onPointerUp={() => {
        //   setSelecting(false);
        // }}
      >
        {slateDoc}
      </div>
    );
  }

  return (
    <div draggable={!!organization.aiEnabled && !selecting && !edit} onDragStart={onDragStart}>
      <KeyNavigationElement
        id={comment.id}
        className={cn(
          styles.comment,
          'unselectable',
          {
            [styles.actionsAlwaysVisible]: actionsAlwaysVisible,
          },
          className
        )}
      >
        <ActorAvatar actorId={comment.actorId} size={AvatarSize.Size24} className="mr8 noGrow" />
        {!edit && (
          <>
            {focused && (
              <Hotkey
                priority={-2}
                hotkey={createNewEntityFromAnywhere}
                handler={(e?: KeyboardEvent | undefined) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  createEntityFromComment();
                }}
              />
            )}

            <div className="grow overflowHidden">
              <div className="row fullWidth overflowHidden">
                <div className={styles.commentActorAndTimestamp}>
                  <ActorName actorId={comment.actorId} className="headingS mr8 ellipsis" />
                  <ActivityTimestamp timestamp={comment.createdAt} />
                </div>
                <CommentActions
                  disableHotkeys={disableHotkeys}
                  comment={comment}
                  onEdit={() => setEdit(true)}
                  additionalActions={additionalActions}
                />
              </div>
              {comment.inline && <InlineCommentContext comment={comment} />}
              {slateDoc}
              {comment.reactions.length > 0 && (
                <Reactions
                  className="mt6"
                  currentUserId={user.id}
                  reactions={comment.reactions}
                  onReactionsChanged={reactions => {
                    updateComments([comment.id], { reactions });
                    markRead(comment.id);
                  }}
                  tooltipContents={(reaction, actorIds) => (
                    <ReactionTooltip reaction={reaction} actorIds={actorIds} />
                  )}
                />
              )}
              {children}
            </div>
          </>
        )}
        {edit && (
          <ExistingCommentEditor comment={comment} onEditingComplete={() => setEdit(false)} />
        )}
      </KeyNavigationElement>
    </div>
  );
}

function CommentActions({
  comment,
  onEdit,
  additionalActions,
  disableHotkeys,
}: {
  comment: CommentModel;
  onEdit: () => void;
  additionalActions?: React.ReactNode;
  disableHotkeys?: boolean;
}) {
  const organization = useOrganization();
  const clientId = useClient();
  const toMarkdown = useSerializeToMarkdown();
  const focused = useHasKeyNavigationFocus(comment.id);
  const user = useCurrentUser();
  const deleteComments = useDeleteComments();
  const updateComments = useUpdateComments();
  const createEntityFromComment = useCreateEntityFromComment(comment);

  const toggleReaction = useToggleReactions(user.id, comment.reactions, reactions =>
    updateComments([comment.id], { reactions })
  );
  const markRead = useMarkUpdatesReadByDependencyId();
  const commentUrl = useCommentUrl();

  const [menuOpen, setMenuOpen] = React.useState(false);
  const closeMenu = React.useCallback(() => setMenuOpen(false), [setMenuOpen]);
  const [emojiPopoverOpen, setEmojiPopoverOpen] = React.useState(false);
  const closeEmojiPopover = React.useCallback(
    () => setEmojiPopoverOpen(false),
    [setEmojiPopoverOpen]
  );

  if (comment.deleted && !(comment.inline && !comment.reply)) {
    return null;
  }

  function Shortcut(props: { shortcut: string }) {
    if (disableHotkeys) {
      return null;
    }
    return <KeyboardShortcut {...props} />;
  }

  return (
    <div
      className={cn(styles.actions, {
        [styles.menuOpen]: menuOpen || emojiPopoverOpen,
      })}
    >
      {additionalActions}
      {comment.inline && !comment.reply && (
        <Tooltip
          content={
            <>
              {comment.resolved ? 'Unresolve' : 'Resolve'} comment thread{' '}
              <Shortcut shortcut={resolveCommentKey} />{' '}
            </>
          }
        >
          <IconButton
            icon={comment.resolved ? 'refresh' : 'tasks'}
            buttonStyle={ButtonStyle.BareSubtle}
            size={ButtonSize.Small}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              updateComments([comment.id], { resolved: !comment.resolved });
            }}
          />
        </Tooltip>
      )}
      {focused && !comment.deleted && (
        <>
          <CommandContext context={{ group: CommandGroup.Comment, commentId: comment.id }} />
          {comment.actorId === user.id && (
            <CustomCommand
              command={{
                id: 'edit-comment',
                group: CommandGroup.Comment,
                hotkey: 'e',
                priority: 5,
                description: 'Edit comment',
                handler: () => {
                  onEdit();
                },
              }}
            />
          )}
          {!disableHotkeys && (
            <CustomCommand
              command={{
                id: 'react-to-comment',
                group: CommandGroup.Comment,
                hotkey: reactionKey,
                description: 'React to comment',
                handler: () => setEmojiPopoverOpen(true),
              }}
            />
          )}
        </>
      )}
      {!comment.deleted && (
        <Tooltip
          disabled={emojiPopoverOpen}
          content={
            <>
              Add reaction <Shortcut shortcut={reactionKey} />
            </>
          }
        >
          <div className="noLineHeight">
            <Popover
              open={emojiPopoverOpen}
              onOpenChange={setEmojiPopoverOpen}
              asChild
              contentOptions={{
                align: 'end',
                alignOffset: -52,
              }}
              content={
                <EmojiPicker
                  onPicked={reaction => {
                    closeEmojiPopover();
                    toggleReaction(reaction);
                    markRead(comment.id);
                  }}
                />
              }
            >
              <IconButton
                icon="emoji"
                buttonStyle={ButtonStyle.BareSubtle}
                size={ButtonSize.Small}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setEmojiPopoverOpen(true);
                }}
              />
            </Popover>
          </div>
        </Tooltip>
      )}
      {!comment.deleted && comment.actorId === user.id && (
        <Tooltip
          content={
            <>
              Edit comment <Shortcut shortcut="e" />
            </>
          }
        >
          <IconButton
            icon="edit"
            buttonStyle={ButtonStyle.BareSubtle}
            size={ButtonSize.Small}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onEdit();
            }}
          />
        </Tooltip>
      )}
      {!comment.deleted && (
        <DropdownMenu onOpenChange={setMenuOpen} open={menuOpen}>
          <DropdownMenuContent side="bottom" align="end">
            <KeyNavigationLockingElement>
              <DropdownMenuItem
                icon="add"
                shortcut={disableHotkeys ? undefined : createNewEntityFromAnywhere}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeMenu();
                  createEntityFromComment();
                }}
              >
                Create {issueTerm}
              </DropdownMenuItem>
              <DropdownMenuItem
                icon="link"
                shortcut={disableHotkeys ? undefined : copyActivityLinkKey}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeMenu();
                  const url = commentUrl(comment);
                  writeToClipboard(url, 'Comment link');
                }}
              >
                Copy link
              </DropdownMenuItem>
              {organization.aiEnabled && (
                <DropdownMenuItem
                  icon="ai"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    closeMenu();

                    const element: AIPlaceholderElement = {
                      type: Elements.AIPlaceholder,
                      clientId,
                      context: {
                        content: toMarkdown(JSON.parse(comment.body)),
                        url: commentUrl(comment),
                      },
                      operation: 'todo',
                      children: [
                        {
                          text: '',
                        },
                      ],
                    };
                    const encodedFragment = window.btoa(
                      encodeURIComponent(JSON.stringify([element]))
                    );
                    const data = new clipboard.ClipboardItem({
                      'text/plain': '',
                      'text/html': new Blob(
                        [`<div data-slate-fragment="${encodedFragment}"> </div>`],
                        { type: 'text/html' }
                      ),
                      'application/x-slate-fragment': encodedFragment,
                    });
                    clipboard.write([data]);
                    toast.info('Todo copied to clipboard. Paste it anywhere to create it.');
                  }}
                >
                  Generate todo from comment
                </DropdownMenuItem>
              )}
              {comment.actorId === user.id && (
                <>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem
                    icon="delete"
                    shortcut={disableHotkeys ? undefined : 'backspace'}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      closeMenu();
                      deleteComments([comment.id]);
                    }}
                  >
                    Delete comment
                  </DropdownMenuItem>
                </>
              )}
            </KeyNavigationLockingElement>
          </DropdownMenuContent>
          <DropdownMenuTrigger asChild>
            <IconButton icon="more" buttonStyle={ButtonStyle.BareSubtle} size={ButtonSize.Small} />
          </DropdownMenuTrigger>
        </DropdownMenu>
      )}
    </div>
  );
}

type CommentEditorProps = {
  onSubmit?: (value: DocumentLike) => void;
  onClear?: () => void;
  onUp?: () => void;
  entityId: string;
} & TextAreaProps;

export function CommentEditorComponent(
  { onSubmit, onClear, onUp, onKeyDown, entityId, className, ...rest }: CommentEditorProps,
  ref?: React.ForwardedRef<TextAreaHandle>
) {
  const editorRef = React.useRef<TextAreaHandle | null>();
  const organization = useOrganization();
  const space = useMaybeSpace();
  const users = useRecoilValue(
    activeUsersForMaybeSpaceSelector({ organizationId: organization.id, spaceId: space?.id })
  );
  const spaces = useRecoilValue(spacesForOrganizationSelector(organization.id));
  const user = useCurrentUser();
  const createIssue = useCreateIssue();
  const createInitiative = useCreateInitiative();
  const search = useSearchOnce();
  const defaultOptionsForSpace = useRecentEntitiesForSpace();
  const defaultOptionsForOrganization = useRecentEntitiesForOrganization();
  const filterInitiatives = useFilterInitiativesForSpace();
  const getTodos = useGetTodosForEntity();

  return (
    <TextArea
      type={TextAreaType.InputLarge}
      className={cn('fs-exclude', styles.editor, className)}
      tabIndex={-1}
      ref={(r: any) => {
        if (ref) {
          if (isFunction(ref)) {
            ref(r);
          } else {
            ref.current = r;
          }
        }
        editorRef.current = r;
      }}
      richText={true}
      isHardBreak={e => e.key === 'Enter' && e.shiftKey && !e.metaKey && !e.ctrlKey}
      isSoftBreak={() => false}
      onKeyDown={e => {
        if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          onClear?.();
        }

        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();
          e.stopPropagation();

          if (editorRef.current && !isDocumentEmpty(editorRef.current.raw().children)) {
            const content = editorRef.current.forceStringMatch(true);
            onSubmit?.(content);
          }
        }

        if (e.key === 'ArrowUp' && editorRef.current?.isOnFirstLine()) {
          e.preventDefault();
          e.stopPropagation();
          onUp?.();
          return;
        }

        onKeyDown?.(e);
      }}
      suggestions={[
        ...entitySuggestionMatcher(
          organization,
          user,
          spaces,
          defaultOptionsForSpace,
          defaultOptionsForOrganization,
          search,
          createIssue,
          (title: string) =>
            createInitiative(title, { spaceIds: space ? [space.id] : [] }).initiative,
          initiatives => filterInitiatives(initiatives, space?.id),
          getTodos
        ),
        userSuggestionMatcher(user, users, {
          spaceId: space?.id,
          organizationId: organization.id,
          entityId,
        }),
      ]}
      bottomOffset={60}
      {...rest}
    />
  );
}

export const CommentEditor = React.forwardRef(CommentEditorComponent);

function NewCommentEditorComponent(
  {
    entityId,
    threadId,
    inline,
    onSubmit,
    onFocus,
    onBlur,
    ...rest
  }: { threadId?: string; inline?: boolean } & Omit<CommentEditorProps, 'initialValue'>,
  ref: React.ForwardedRef<TextAreaHandle>
) {
  const bottomMarginRef = React.useRef<HTMLDivElement>(null);
  const editorRef = React.useRef<TextAreaHandle | null>(null);
  const createComment = useCreateComment();
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();
  const move = useMove();

  const entityIdRef = React.useRef(entityId);
  entityIdRef.current = entityId;
  const threadRef = React.useRef(threadId);
  threadRef.current = threadId;

  const [newComment, setNewComment, persistComment] = useEditableComment(threadId ?? entityId);

  function submit(value: DocumentLike) {
    if (isDocumentEmpty(newComment) || !entityIdRef.current) {
      return;
    }

    createComment(entityIdRef.current, JSON.stringify(value), {
      threadId: threadRef.current,
      inline,
    });
    editorRef.current?.clear();

    setNewComment(emptyDocument());
    persistComment(emptyDocument());

    setTimeout(() => {
      if (!bottomMarginRef.current) {
        return;
      }
      scrollIntoView(bottomMarginRef.current, { scrollMode: 'if-needed', block: 'end' });
    });
  }

  return (
    <>
      <CommentEditor
        ref={r => {
          if (ref) {
            if (isFunction(ref)) {
              ref(r);
            } else {
              ref.current = r;
            }
          }
          editorRef.current = r;
        }}
        entityId={entityId}
        placeholder={
          <div className="rowBetween fullWidth">
            <div>{threadId ? 'Leave a reply' : 'Leave a comment'}</div>
            <KeyboardShortcut shortcut="r" />
          </div>
        }
        initialValue={newComment}
        onChange={v => setNewComment(v)}
        onClear={() => {
          editorRef.current?.blur();
        }}
        onFocus={() => {
          editorRef.current?.moveSelectionToEnd();
          onFocus?.();
          disableKeyNav(`new-comment`);
        }}
        onBlur={() => {
          persistComment(newComment);
          onBlur?.();
          enableKeyNav(`new-comment`);
        }}
        onSubmit={value => {
          submit(value);
          onSubmit?.(value);
        }}
        onUp={() => {
          if (editorRef.current) {
            editorRef.current.blur();
            move('up');
          }
        }}
        {...rest}
      />
      <Hotkey
        hotkey="r"
        handler={e => {
          e?.preventDefault();
          e?.stopPropagation();
          editorRef.current?.focus();
        }}
      />
      <div ref={bottomMarginRef}></div>
    </>
  );
}

export const NewCommentEditor = React.forwardRef(NewCommentEditorComponent);

function ExistingCommentEditorComponent(
  {
    comment,
    onEditingComplete,
    ...rest
  }: { comment: CommentModel; onEditingComplete: () => void } & Omit<
    CommentEditorProps,
    'initialValue' | 'entityId'
  >,
  ref: React.ForwardedRef<TextAreaHandle>
) {
  const editorRef = React.useRef<TextAreaHandle | null>(null);
  const updateComments = useUpdateComments();
  const enableKeyNav = useEnableKeyNavigation();
  const disableKeyNav = useDisableKeyNavigation();

  function submitEdit(value: DocumentLike) {
    const parsedBody = JSON.parse(comment.body);
    if (parsedBody && isEqual(parsedBody, value)) {
      onEditingComplete();
      return;
    }

    updateComments([comment.id], { body: JSON.stringify(value) });
    onEditingComplete();
  }

  useComponentDidMount(() => {
    disableKeyNav('existing-comment');
    return () => enableKeyNav('existing-comment');
  });

  return (
    <div className="fullWidth">
      <CommentEditor
        {...rest}
        autoFocus
        ref={r => {
          if (ref) {
            if (isFunction(ref)) {
              ref(r);
            } else {
              ref.current = r;
            }
          }
          editorRef.current = r;
        }}
        entityId={comment.entityId}
        placeholder={'Edit your comment'}
        initialValue={JSON.parse(comment.body)}
        onClear={() => {
          onEditingComplete();
        }}
        onSubmit={value => {
          submitEdit(value);
        }}
      />
      <div className="rowEnd mt12">
        <Button onClick={onEditingComplete} size={ButtonSize.Small} className="mr8">
          Cancel
        </Button>
        <Button
          size={ButtonSize.Small}
          onClick={() => {
            if (!editorRef.current) {
              return;
            }
            submitEdit(editorRef.current.raw().children);
          }}
          buttonStyle={ButtonStyle.Primary}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

const ExistingCommentEditor = React.forwardRef(ExistingCommentEditorComponent);

export function NewComment({
  entityId,
  scrollParent,
}: {
  entityId: string;
  scrollParent: React.RefObject<HTMLDivElement>;
}) {
  const focused = useHasKeyNavigationFocus(NEW_COMMENT_ID);
  const setFocus = useSetKeyNavigationFocus();

  const move = useMove();

  const editorRef = React.useRef<TextAreaHandle>(null);

  useKeyNavigationWatcher(({ focused, focusedReason }) => {
    if (focused === NEW_COMMENT_ID && focusedReason === FocusReason.Keyboard) {
      editorRef.current?.focus();
    }
  });

  return (
    <div
      className={styles.newCommentContainer}
      onDragOver={() => {
        editorRef.current?.focus();
      }}
      {...{ [KEY_NAVIGATION_DATA_ATTRIBUTE]: NEW_COMMENT_ID }}
    >
      <NewCommentEditor
        ref={editorRef}
        className={cn({
          [styles.newCommentKeyNavFocus]: focused,
        })}
        entityId={entityId}
        onFocus={() => {
          setFocus(NEW_COMMENT_ID);
        }}
        onSubmit={() => {
          if (scrollParent.current) {
            scrollParent.current.scrollTop = 0;
          }
          setTimeout(() => setFocus(NEW_COMMENT_ID));
        }}
        onKeyDown={e => {
          if (e.key === 'ArrowLeft' && editorRef.current?.isAtStart()) {
            editorRef.current.blur();
            move('left');
          }
        }}
      />
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            editorRef.current?.focus();
          }}
        />
      )}
    </div>
  );
}

export function CommentActivity({ commentId }: { commentId: string }) {
  const history = useHistory();
  const ref = React.useRef<HTMLDivElement>(null);
  const focused = useHasKeyNavigationFocus(commentId);
  const topLevelComment = useRecoilValue(commentSelector(commentId));
  const comments = useRecoilValue(commentsForThreadSelector(topLevelComment?.threadId ?? ''));

  if (!topLevelComment || !comments) {
    return null;
  }

  if (comments.every(comment => comment.deleted)) {
    return null;
  }
  const sortedComments = sortBy(comments, comment => comment.createdAt);
  const [comment, ...replies] = sortedComments;
  const filteredReplies = replies.filter(r => !r.deleted);
  const participantIds = uniq(filteredReplies.map(c => c.actorId).filter(c => !!c));

  const participants = participantIds.map(participantId => {
    return <ActorAvatar key={participantId} actorId={participantId} />;
  });

  function open() {
    if (!topLevelComment) {
      return;
    }
    setQueryParameter(history, COMMENT_THREAD_ID_QUERY_PARAM, topLevelComment?.threadId);
  }

  return (
    <div
      className={cn(styles.commentActivity, {
        [styles.focused]: focused,
      })}
      ref={ref}
    >
      <Comment
        comment={comment}
        additionalActions={
          <>
            <Tooltip
              content={
                <>
                  Open thread <KeyboardShortcut shortcut="enter" />
                </>
              }
            >
              <IconButton
                icon="reply"
                buttonStyle={ButtonStyle.BareSubtle}
                size={ButtonSize.Small}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  open();
                }}
              />
            </Tooltip>
          </>
        }
      >
        {filteredReplies.length > 0 && (
          <div
            className={cn(styles.replies)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              open();
            }}
          >
            <div className="row metadataGap mr8">{participants}</div>
            <div className="headingS tight oneLine mr16">{filteredReplies.length} replies</div>
            <div className={styles.viewReplies}>View replies</div>
            <div className={styles.lastReply}>
              Last reply{' '}
              {renderDate(filteredReplies[filteredReplies.length - 1].createdAt, {
                showTime: true,
              })}
            </div>
          </div>
        )}
      </Comment>
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            open();
          }}
        />
      )}
    </div>
  );
}

function NewCommentReply({
  entityId,
  threadId,
  editorRef,
}: {
  entityId: string;
  threadId: string;
  editorRef: React.RefObject<TextAreaHandle>;
}) {
  const focused = useHasKeyNavigationFocus(NEW_COMMENT_ID);
  const setFocus = useSetKeyNavigationFocus();
  const markUpdatesReadByKey = useMarkUpdatesReadByKey();

  useKeyNavigationWatcher(({ focused, focusedReason }) => {
    if (focused === NEW_COMMENT_ID && focusedReason === FocusReason.Keyboard) {
      editorRef.current?.focus();
    }
  });

  return (
    <div
      className={styles.newCommentReplyContainer}
      onDragOver={() => setFocus(NEW_COMMENT_ID)}
      {...{ [KEY_NAVIGATION_DATA_ATTRIBUTE]: NEW_COMMENT_ID }}
    >
      <NewCommentEditor
        ref={editorRef}
        className={cn({
          [styles.newCommentKeyNavFocus]: focused,
        })}
        threadId={threadId}
        entityId={entityId}
        onFocus={() => {
          setFocus(NEW_COMMENT_ID);
        }}
        onSubmit={() => {
          markUpdatesReadByKey(threadId);
        }}
      />
      {focused && (
        <Hotkey
          hotkey="enter"
          handler={e => {
            e?.preventDefault();
            e?.stopPropagation();
            editorRef.current?.focus();
          }}
        />
      )}
    </div>
  );
}

function CommentThreadComment({ comment }: { comment: CommentModel }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const focused = useHasKeyNavigationFocus(comment.id);

  return (
    <div
      ref={ref}
      className={cn(styles.commentThreadComment, {
        [styles.focus]: focused,
      })}
    >
      <Comment comment={comment} className="noBorderRadius" />
    </div>
  );
}

export function CommentThread({ entityId, threadId }: { entityId: string; threadId: string }) {
  const ref = React.useRef<HTMLDivElement>(null);
  const editorRef = React.useRef<TextAreaHandle>(null);

  const user = useCurrentUser();
  const history = useHistory();
  const ensureVisible = useEnsureFocusedElementIsVisible();
  const markUpdatesReadByDependencyId = useMarkUpdatesReadByDependencyId();
  const commentsForThread = useRecoilValue(commentsForThreadSelector(threadId))?.filter(
    c => !c.reply || !c.deleted
  );
  const setFocus = useSetKeyNavigationFocus();
  const getKeyNavState = useGetKeyNavigationState();

  useKeyNavigationColumn(
    `right-${entityId}`,
    [...(commentsForThread ?? []).map(c => c.id), NEW_COMMENT_ID],
    ref
  );

  React.useEffect(() => {
    if (!commentsForThread?.length) {
      return;
    }
    markUpdatesReadByDependencyId(commentsForThread[0].id);

    if (commentsForThread[0].inline) {
      scrollCommentIntoView(commentsForThread[0].id);
    }

    // if one of the comments already has focus (like when we're coming from a notification)
    // then don't muck with the focus
    const ids = commentsForThread.map(c => c.id).slice(1);
    const focused = getKeyNavState().focused;
    if (focused && ids.includes(focused)) {
      ensureVisible();
      return;
    }

    const userHasParticipatedInThread = !!commentsForThread.find(c => c.actorId === user.id);
    setFocus(userHasParticipatedInThread ? NEW_COMMENT_ID : commentsForThread[0].id);
    if (userHasParticipatedInThread) {
      editorRef.current?.focus();
    }
  }, [threadId, user.id]);

  if (!commentsForThread?.length) {
    return null;
  }

  function close() {
    removeQueryParameter(history, 'commentThreadId');
    if (commentsForThread?.[0]) {
      setFocus(commentsForThread[0].id);
    }
  }

  const numberOfReplies = commentsForThread.length - 1;
  const renderedComments = commentsForThread.map(comment => (
    <CommentThreadComment key={comment.id} comment={comment} />
  ));

  return (
    <div className={styles.commentThreadContainer}>
      <div className={styles.commentThreadHeader}>
        <div>Replies</div>
        <Tooltip
          content={
            <>
              Close thread <KeyboardShortcut shortcut="escape" />
            </>
          }
        >
          <IconButton
            icon="exit"
            buttonStyle={ButtonStyle.Bare}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              close();
            }}
          />
        </Tooltip>
      </div>
      <div className={styles.commentThread} ref={ref}>
        {renderedComments[0]}
        <Divider leftAlign>
          {numberOfReplies} {numberOfReplies === 1 ? 'reply' : 'replies'}
        </Divider>
        {renderedComments.slice(1)}
        <NewCommentReply entityId={entityId} threadId={threadId} editorRef={editorRef} />
      </div>
      <CustomCommand
        command={{
          id: 'close-comment',
          hotkey: 'escape',
          priority: 0,
          group: CommandGroup.Comment,
          description: 'Close comment thread',
          handler: () => {
            close();
          },
        }}
      />
    </div>
  );
}
